.container {
    padding: 2.5% 7%;
    margin-bottom: 50px;
}

.topMenu {
    margin: 30px 0px 60px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.search {
    border: solid 1px #2da028;
    border-radius: 20px !important;
    width: 40%;
    min-width: 400px;
}

.IconRound {
    background-color: #00000013;
    min-width: 75px;
    min-height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    box-shadow: 20px 20px 60px #bebebe33,
        -20px -20px 60px #ffffff33;
}

.progressSpinContainer {
    height: 125px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.addBox {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.whiteBox {
    display: grid;
    place-items: center;
    width: 170px;
    height: 35px;
    color: black;
    background-color: #fff;
}

.wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(370px, 2fr));
    grid-gap: 1.5rem;
    margin-bottom: 70px;
    flex-wrap: wrap;
}

.dashboardCol {
    box-shadow: 20px 20px 60px #bebebe44,
        -20px -20px 60px #ffffff44;
    width: 32%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    background-color: white;
    padding: 0px 35px;
    border-radius: 15px;
    min-width: 310px;
    border: rgba(0, 0, 0, 0.15) solid 0.1px;

}

.count {
    font-weight: 800;
    font-size: 16px;
    margin-bottom: 0px;
}

.colText {
    margin-bottom: 0px;
    font-size: 14px;
    /* line-height: 2px; */
    color: #020202;
    font-weight: 600;

}

@media only screen and (max-width: 1200px) {
    .wrapper {
        grid-template-columns: repeat(auto-fit, minmax(340px, 2fr));
    }
}

@media only screen and (max-width: 1000px) {
    .container {
        /* min-height: 100vh; */
        margin-bottom: 50px;
        padding: 2.5%;
    }

    .wrapper {
        grid-template-columns: repeat(auto-fit, minmax(350px, 2fr));
    }
}

@media only screen and (max-width: 800px) {
    .search {
        width: 100%;
    }

    .topMenu {
        margin: 12% 0%;
        width: 100%;
        flex-direction: column-reverse;
        align-items: flex-start;
    }

    .addBox {
        font-size: 14px;
        width: 140px;
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 600px) {
    .container {
        padding: 1rem;
    }

    .LgStatusButton {
        display: none !important;
    }

    .topMenu {
        margin: 0px 0px 60px 0px;
    }
}

@media only screen and (max-width: 500px) {
    .dashboardCol {
        gap: 5px;
        padding: 3px;
        border-radius: 15px;
        width: 25%;
    }

    .colText {
        font-size: 10px;
    }
}

@media only screen and (max-width: 400px) {
    .container {
        padding: 0.5rem;
    }

    .topMenu {
        margin: 0px 0px 60px 0px;
    }
}


.tableStatusContainer {
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    line-height: 7px;
    gap: 7px;
    font-weight: 700;
    margin-top: 7px;

}

.tableStatus {
    height: 7px;
    width: 7px;
    border-radius: 100%;
}

.typeContainer {
    background-color: #3AB44D22;
    width: fit-content;
    text-align: center;
    block-size: fit-content;
    border: 1px #3AB44D55 solid;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    justify-self: center;
}

.typeText {
    margin: 5px 10px;
}

.headerText {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #171717;
    margin-top: 15px;
}

.breadCrumb1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    display: flex;
    align-items: center;
    text-align: center;

    /* Black/100% */

    color: #1C1C1C;
}

.breadCrumb2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #1C1C1C;
}


.memberDetailHeader {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */

    letter-spacing: -0.02em;

    /* Neutral/Main (700) */

    color: #404040;
    margin-top: -10px;
    /* margin-left: 19px; */
}

.cardShadow {
    background: #FFFFFF;
    /* Shadow/Large */
    padding: 20px 35px;
    /* height: 65px; */
    margin-top: 75px;
    /* width: 150px; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-shadow: 0px 16px 44px rgba(82, 82, 82, 0.05);
    border-radius: 15px;
    transition: all 0.25s ease-in-out;
}


.banner {
    background: #FFFFFF;
    /* Shadow/Large */

    box-shadow: 0px 16px 44px rgba(82, 82, 82, 0.05);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px;
    width: 100%;
    height: 200px;
    padding-top: 35px;
}

.bannerText {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    /* or 111% */

    letter-spacing: -0.02em;

    /* Neutral/Main (900) */

    color: #171717;
}

.bannerSubText {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    /* or 138% */

    letter-spacing: -0.02em;

    /* Neutral/Main (800) */

    color: #262626;
    margin-top: -5px;
}

.bannerBtn {
    justify-self: flex-start;
    align-self: flex-start;
    width: 185px;
    height: 32px;
    background: #3AB44D;
    border-radius: 50px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 150% */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;

    align-self: flex-end;
    /* Shades/White */
    position: absolute;
    right: 0px;
    bottom: 0px;

    color: #FFFFFF;
    padding: 6px 33.5px;
    /* margin-right: 10px; */
    /* margin-bottom: -5px; */
}

.tableSearchBox {
    /* margin-top: -10px; */
    width: 375px;
    height: 46px;
    background: #FFFFFF;
    /* Neutral/Main (300)
  
  These colours are used as supporting secondary colours in backgrounds, text colours, separators, models.
  */
    border: 1px solid #D4D4D4;
    border-radius: 15px;
    justify-self: flex-end;
    align-self: flex-end;
}

.benefitsText {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    letter-spacing: -0.01em;

    color: #646464;

}

.panelHeader {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 28px;
    /* identical to box height, or 156% */

    letter-spacing: -0.02em;

    /* Neutral/Main (700) */

    color: #404040;
    margin-top: 5px;
}

.panelIcon {
    font-size: 16px;
    color: #3AB44D;
    margin-top: 12px;
}

.benefitsTableLabel {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */

    letter-spacing: -0.02em;

    /* Shades/Black */

    color: #000000;

}

.benefitsTableText {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 16px;
    /* identical to box height, or 150% */

    letter-spacing: -0.02em;

    /* Neutral/Main (800) */

    color: #262626;
    margin-bottom: -5px;

}

.exclusionsCollapse {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */

    letter-spacing: -0.02em;

    /* Shades/Black */

    color: #000000;
}

.exclusionsData {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: -0.02em;

    color: #F59E0B;
    margin-left: -15px;
    margin-bottom: -10px;
}


.divider {
    border-top: 1px solid #E5E5E5;
}

.cardTitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* or 111% */

    letter-spacing: -0.02em;

    /* Neutral/Main (900) */

    color: #171717;
}

.cardSubTitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    /* or 138% */

    letter-spacing: -0.02em;

    /* Neutral/Main (800) */

    color: #262626;

}

.cardTitle2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    letter-spacing: -0.02em;

    color: #737373;
    margin-top: -10px !important;
    margin: 0px;
}


.dataTableLabel {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: -0.02em;

    /* Neutral/Main (800) */

    color: #262626;
    margin-top: -10px;
}

.dataTableValue {
    /* Paragraph/Medium/Light */

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 150% */

    text-align: right;
    letter-spacing: -0.02em;

    /* Neutral/Main (500) */

    color: #737373;
    margin-top: -10px;
}

.stepStatus {
    /* Paragraph/Medium/Light */

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: -0.02em;

    /* Neutral/Main (500) */

    color: #737373;
    margin-top: -15px;
}

.stepTime {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 129% */

    letter-spacing: -0.02em;

    /* Shades/Black */

    color: #000000;
    margin-top: -15px;

}

.stepTitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: -0.02em;

    /* Neutral/Main (800) */

    color: #262626;

}

.cardTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #000000;
    margin: 0px
}

.cardNumber {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.02em;
    color: #171717;
    margin: 0px;
    margin-top: 10px;
}

.cardNumber2 {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: #171717;
    margin: 0px;
    margin-left: 10px;
    margin-top: 0px;
}

.input {
    border-radius: 13px !important;
    padding: 5px 15px;
    font-family: 'Poppins';
    font-style: normal;
    width: 300px;
}

.formLabel {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #262626;
    margin: 0px;
}

.formValue {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #262626;
    margin: 0px;
}

.formTitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #262626;
    margin-bottom: 25px;
    margin-top: -10px;
    margin: 20px 0px;
}

.title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #262626;
    margin-bottom: -10px;
    /* margin: 20px 0px; */
}

.subTitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #262626;
    margin-bottom: 35px;
    /* margin: 20px 0px; */
}